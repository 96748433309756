<template>
  <div class="role">
    <div class="role-action">
      <el-form label-width="80px" :inline="true">
        <el-form-item label="角色名称">
          <el-input v-model="search.roleName"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-refresh-left" @click="handleReset"
            >重置</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="state.drawerAdd = true"
            >新增</el-button
          >
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="danger"
                     icon="el-icon-delete">删除</el-button>
        </el-form-item> -->
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="handleExport"
            >导出角色</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <el-table
      :data="state.roleList"
      style="width: 100%"
      v-loading="state.loading"
      border
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="角色id" align="center">
      </el-table-column>
      <el-table-column prop="roleName" label="角色名称" align="center">
      </el-table-column>

      <el-table-column prop="roleIndex" label="排序" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button
            type="text"
            size="small"
            @click.stop="handleAuthUser(scope.row)">
            授权用户
          </el-button>
          <el-button type="text" @click="handleRoleEdit(scope.row)"
            >修改角色</el-button
          >
          <el-button type="text" @click="handleRoleConfig(scope.row.id)"
            >配置菜单</el-button
          >
          <el-button
            type="text"
            @click="handleAppConfig(scope.row.id)"
            v-if="systemCode == 10"
            >配置应用</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        background
        :current-page="page.currPage"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>

    <el-drawer
      title="配置菜单权限"
      :visible.sync="state.drawerConfig"
      direction="rtl"
      size="30%;min-width:500px;">
      <div style="padding: 10px 20px; position: relative; height: 100%">
        <el-tree
          :data="state.menuList"
          v-loading="treeLoading"
          node-key="id"
          :defaultProps="{ children: 'children', label: 'label', id: 'id' }"
          :default-checked-keys="state.dataDefaultCheckKes"
          @check="handleCheck"
          show-checkbox>
        </el-tree>
        <div style="position: fixed; bottom: 30px">
          <el-button @click="state.drawerConfig = false">取消</el-button>
          <el-button type="primary" @click="handleMenuSubmit">保存</el-button>
        </div>
      </div>
    </el-drawer>

    <el-drawer
      title="配置应用权限"
      :visible.sync="application.drawerApp"
      direction="rtl"
      size="30%;min-width:500px;">
      <div style="padding: 10px 20px; position: relative; height: 100%">
        <el-tree
          :data="application.appList"
          node-key="id"
          :defaultProps="{ children: 'children', label: 'label', id: 'id' }"
          :default-checked-keys="application.appListDefaultCheck"
          @check="handleSelectApp"
          show-checkbox>
        </el-tree>
        <div style="position: fixed; bottom: 30px">
          <el-button @click="state.drawerConfig = false">取消</el-button>
          <el-button type="primary" @click="handleMenuSubmit">保存</el-button>
        </div>
      </div>
    </el-drawer>

    <el-drawer
      :title="state.isRoleEdit ? '修改角色' : '新增角色'"
      :visible.sync="state.drawerAdd"
      v-if="state.drawerAdd"
      direction="rtl"
      size="30%;min-width:500px;"
      :before-close="
        () => (
          (state.isRoleEdit = false),
          (state.drawerAdd = false),
          (state.form = {})
        )
      ">
      <div style="padding: 10px 20px">
        <el-form
          label-width="80px"
          :model="state.form"
          :inline="false"
          :rules="rules">
          <el-form-item label="父级角色">
            <!-- <el-input v-model="state.form.parentId"></el-input> -->
            <el-cascader
              placeholder="请选择角色"
              v-model="state.form.parentId"
              filterable
              :show-all-levels="false"
              :options="state.roleOptions"
              @change="
                () => (state.form.parentId = state.form.parentId.pop())
              "></el-cascader>
          </el-form-item>
          <el-form-item label="角色名称">
            <el-input v-model="state.form.roleName"></el-input>
          </el-form-item>
          <el-form-item label="角色编号">
            <el-input v-model="state.form.code"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="state.form.roleIndex"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click=";(state.drawerAdd = false), (state.form = {})"
              >取消</el-button
            >
            <el-button type="primary" @click="handleSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

    <el-dialog
      title="角色账号管理"
      :visible.sync="dialogConfig.dialogVisible"
      width="600px"
      :before-close="beforeClose"
      :destroy-on-close="true"
      :close-on-click-modal="false">
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;
        ">
        <el-button
          type="primary"
          size="small"
          :disabled="dialogConfig.disabled"
          @click="handelDeleteRoleUser"
          >解绑账号</el-button
        >
        <el-radio-group v-model="dialogConfig.radio" size="small">
          <el-radio-button :label="0">已添加账号</el-radio-button>
          <el-radio-button :label="1">可添加账号</el-radio-button>
        </el-radio-group>
      </div>
      <div
        v-show="dialogConfig.radio == 0"
        class="role-table"
        style="height: 500px">
        <el-table
          ref="listTable"
          v-loading="dialogConfig.roleLoading"
          :data="dialogConfig.userList"
          height="452px"
          style="width: 100%"
          border
          @selection-change="hanleRowSelect">
          <el-table-column type="selection" />
          <el-table-column label="账号" prop="account" align="center" />
          <el-table-column label="姓名" prop="realname" align="center" />
          <el-table-column label="创建时间" prop="createtime" align="center" />
        </el-table>
        <div style="padding: 20px; text-align: center">
          <el-pagination
            :current-page="dialogConfig.page.index"
            :page-size="10"
            layout="total, prev, pager, next"
            :total="dialogConfig.page.totalCount"
            @current-change="handleCurrentDialogChange" />
        </div>
      </div>
      <div
        v-show="dialogConfig.radio == 1"
        class="worker"
        style="max-height: 570px">
        <workerList @onBindUser="onBindUser" />
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { Message } from 'element-ui'
import {
  roleListPage,
  roleAdd,
  roleUpdate,
  roleBatchadd,
  roleMenuList,
  allRoleList,
  roleUserList,
  roleBindUser,
  deleteRoleUser
} from '@/api/system/role'
import { deptList } from '@/api/system/user'
import { menuList } from '@/api/system/menu'
import { systemListPage } from '@/api/system/menu'
import { getSession } from '@/utils/storage'
import { toTree } from '@/utils/index'
import useExportJson from '@/utils/useExportJson'
import workerList from './components/workerList'

import { watch } from 'vue'

const systemCode = process.env.VUE_APP_SYSTEM_CODE

const rules = {}

const state = reactive({
  roleList: [],
  drawerConfig: false,
  drawerAdd: false,
  isRoleEdit: false,
  form: {},
  roleId: '',
  menuList: [],
  menuListUnfomat: [],
  selectMenuId: [],
  dataDefaultCheckKes: [],
  deptOptions: [],
  roleOptions: [],
  loading: false
})

const dialogConfig = reactive({
  dialogVisible: false, // 角色授权管理弹窗
  selectRoleItem: {}, // 选中的角色信息
  userList: [],
  selectUser: {},
  roleLoading: false,
  disabled: true,
  radio: 0,
  page: {
    index: 1,
    totalCount: 1
  }
})

const treeLoading = ref(false)

// 配置应用列表
const application = reactive({
  drawerApp: false,
  appList: [],
  appListDefaultCheck: [],
  appSelectList: []
})

// 搜索条件
const search = reactive({ roleName: '' })

// 分页
const page = reactive({
  currPage: 1,
  pageSize: 10,
  totalCount: 1
})

watch(
  () => dialogConfig.dialogVisible,
  val => {
    if (val) getRoleUserList()
  }
)

// 获取角色列表
const getRoleList = async (currPage = 1, pageSize = 10) => {
  state.loading = true
  try {
    const { data, totalPage, totalCount } = await roleListPage({
      ...search,
      systemId: systemCode,
      currPage,
      pageSize
    })
    state.roleList = data
    page.totalCount = totalCount
  } finally {
    state.loading = false
  }
}

const handleAuthUser = item => {
  dialogConfig.selectRoleItem = item
  dialogConfig.dialogVisible = true
}

const getRoleUserList = (page = 1) => {
  roleUserList({
    roleId: dialogConfig.selectRoleItem.id,
    currPage: page,
    pageSize: 10
  }).then(res => {
    dialogConfig.userList = res?.data
    dialogConfig.page.totalCount = res?.totalCount
  })
}
// 选中用户信息
const hanleRowSelect = itemArr => {
  dialogConfig.selectUser = itemArr.map(item => item.arid)
  dialogConfig.disabled = !itemArr.length
}

const beforeClose = () => {
  dialogConfig.dialogVisible = false
  dialogConfig.page = {
    index: 1,
    totalCount: 1
  }
}
const handleCurrentDialogChange = page => {
  dialogConfig.page.index = page
  getRoleUserList(page)
}
const onBindUser = userIdList => {
  roleBindUser({
    accountRoleList: userIdList.map(item => ({
      accountId: item,
      roleId: dialogConfig.selectRoleItem.id
    })),
    systemId: systemCode
  }).then(res => {
    if (res.code == 0) {
      Message.success('绑定成功！')
      getRoleUserList(dialogConfig.page.index)
      dialogConfig.radio = 0
    }
  })
}
const handelDeleteRoleUser = () => {
  deleteRoleUser(dialogConfig.selectUser).then(res => {
    if (res.code == 0) {
      getRoleUserList(dialogConfig.page.index)
      Message.success('解绑成功！')
      dialogConfig.selectUser = []
      dialogConfig.disabled = true
    }
  })
}

const init = async () => {
  getRoleList()
  try {
    // 获取部门列表
    // const { data } = await deptList()
    // state.deptOptions = toTree(data, {
    //   parentId: 'parentId',
    //   id: 'id',
    //   label: 'deptName'
    // })

    // 获取角色列表
    const roleRes = await allRoleList({ system_id: systemCode })
    const roleTree = toTree(roleRes.data, {
      parentId: 'parentId',
      id: 'id',
      label: 'roleName'
    })

    state.roleOptions = [
      {
        id: '0',
        parentId: '0',
        deptId: '0',
        label: '无父级',
        value: '0',
        roleName: '无父级',
        systemId: '10'
      },
      ...roleTree
    ]
  } catch (err) {
    console.log(err)
  }
}
init()

// 导出角色json
const handleExport = async () => {
  const { data } = await roleListPage({
    systemId: systemCode,
    currPage: 1,
    pageSize: page.totalCount
  })

  useExportJson(data, 'role.json')
}

// 搜索
const handleSearch = () => {
  getRoleList(page.currPage, page.pageSize)
}
// 重置
const handleReset = () => {
  search.roleName = ''
  getRoleList(page.currPage, page.pageSize)
}

// 配置应用权限 选中
const handleSelectApp = (data, { checkedKeys }) => {
  application.appSelectList = checkedKeys
}

// 配置应用
const handleAppConfig = async id => {
  try {
    application.drawerApp = true
    const { data } = await systemListPage({
      systemId: systemCode,
      currPage: 1,
      pageSize: 50
    })
    application.appList = data.map(item => ({
      id: item.id,
      label: item.sysName
    }))
  } catch (err) {
    console.warn(err)
  }
}

// 点击配置
const handleRoleConfig = async id => {
  state.drawerConfig = true
  state.roleId = id
  treeLoading.value = true
  await getMenuList()
  try {
    // 获取已有角色菜单
    state.dataDefaultCheckKes = []
    const { data } = await roleMenuList({ role_id: state.roleId })
    const list = data
      .map(item => item.menuId)
      .filter(item => {
        const findData = state.menuListUnfomat.find(items => {
          return item == items.umParentId
        })
        return !(findData && findData.umParentId == item)
      })
    state.dataDefaultCheckKes = list
    treeLoading.value = false
  } catch (e) {
    treeLoading.value = false
  }
}

// 保存配置菜单权限
const handleMenuSubmit = async () => {
  const { roleId, selectMenuId } = state
  const { msg } = await roleBatchadd({ roleId, menuList: selectMenuId })
  Message.success(msg)
  state.drawerConfig = false
}

// 修改角色
const handleRoleEdit = item => {
  state.form = item
  state.drawerAdd = true
  state.isRoleEdit = true
}

// 菜单选中
const handleCheck = (data, { checkedKeys, halfCheckedKeys }) => {
  state.selectMenuId = [...checkedKeys, ...halfCheckedKeys]
}

const handleSizeChange = pageSize => {
  getRoleList(page.currPage, pageSize)
}
const handleCurrentChange = currPage => {
  page.currPage = currPage
  getRoleList(currPage, page.pageSize)
}

// 新增、修改角色提交
const handleSubmit = async () => {
  const { deptId, parentId, roleName, code, roleIndex, id } = state.form
  const params = {
    systemId: systemCode,
    deptId,
    parentId,
    roleName,
    code,
    roleIndex
  }
  const { msg } = (await state.isRoleEdit)
    ? roleUpdate({ ...params, id })
    : roleAdd(params)

  Message.success(msg || 'success')
  state.isRoleEdit = false
  state.drawerAdd = false
  state.form = {}
  setTimeout(() => getRoleList(), 1000)
}

// 获取用户菜单
const getMenuList = async () => {
  const { data } = await menuList({ system_id: systemCode })
  state.menuListUnfomat = data
  state.menuList = toTree(data, {
    parentId: 'umParentId',
    id: 'id',
    label: 'umName'
  })
}
</script>

<style lang="scss" scoped>
.role {
  &-action {
    background-color: #fff;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .action {
    display: flex;
    flex-wrap: wrap;
  }

  .page {
    margin: 30px 0;
    padding: 20px 0;
    text-align: center;
    background-color: #fff;
  }
}
</style>
