import router from './router'
// import { Message } from 'element-ui'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getStorage, setStorage, setSession } from '@/utils/storage' // get token from cookie
import getPageTitle from '@/utils/getPageTitle'

import flowPath from '@/router/modules/flow'
import overseasDataPath from '@/router/modules/overseasData'

import { ssoLoginJump } from '@/utils'

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

const whiteList = [
  '/authorization',
  '/login',
  '/redirectlogin',
  '/redirect',
  '/404',
  '/welcome',
  '/attractPlatform/messageCollect',
  '/attractPlatform/createClient',
  '/businessPlan/businessPlanDetail',
  '/businessPlan/editMineBusinessPlan',
  '/commonConfig/dataJsonTable',
  '/oa/testFlow'
] // no redirect whitelist
const flowList = flowPath().map(item => '/flow/' + item.path)
const overseasDataList = overseasDataPath().map(
  item => '/overseasData/' + item.path
)

router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    setStorage('token', to.query.token)
  }
  const reg_oa = /^\/oa\//g
  NProgress.start()
  document.title = getPageTitle(to.meta.title)
  const hasToken = getStorage('token')
  if (hasToken) {
    // 如果token已经过了失效期，则跳转到登录页面
    const tokenDeadline = getStorage('tokenDeadline')
    if (
      tokenDeadline &&
      new Date(tokenDeadline).getTime() < new Date().getTime()
    ) {
      store.dispatch('user/logout')
      ssoLoginJump()
      return
    }

    if (to.path === '/login') {
      next({
        path: '/'
      })
      NProgress.done()
    } else {
      if (to.path === '/paila') {
        NProgress.done()
        next()
        return
      }

      if (to.query.from === 'bpm') {
        //&& to.query.ssoToken
        //bpm授权登录入口
        loginNext(to, next)
        return
      }
      // const hasGetUserInfo = getStorage('userInfo')
      if (
        reg_oa.test(to.path) &&
        to.query.loginid &&
        (to.query.from === 'oa' || to.query.from === 'bpm')
      ) {
        setSession('systemCode', '10026')
        await store.dispatch('user/tempCodeLogin', {
          account: to.query.loginid
        })
        loginNext(to, next)
        return
      }

      const menuList = change(store.getters.menuList)
      const isInclude = menuList.some(item => item.path === to.path)
      if (
        isInclude ||
        [...flowList, ...overseasDataList, ...whiteList].includes(to.path)
      ) {
        loginNext(to, next)
      } else {
        // 没有该页面权限返回首页
        NProgress.done()
        next('/welcome')
      }
    }
  } else {
    /* has no token*/
    console.log(to.path)
    if (to.path === '/login') {
      // in the free login whitelist, go directly
      next()
    } else {
      if (
        [
          '/authLogin',
          '/404',
          '/authorization',
          '/redirectlogin',
          '/paila'
        ].includes(to.path)
      ) {
        next()
        NProgress.done()
        return
      }

      if (reg_oa.test(to.path) && to.query.loginid) {
        try {
          setSession('systemCode', '10026')
          await store.dispatch('user/tempCodeLogin', {
            account: to.query.loginid
          })
          loginNext(to, next)
        } catch (err) {
          console.log(err)
        }

        next()
        return
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        store.dispatch('user/logout')

        ssoLoginJump()
        // next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

function loginNext(to, next) {
  if (!store.getters.userInfo.accountId) {
    store
      .dispatch('user/getUserInfo')
      .then(() => {
        next()
        store.dispatch('commonConfig/asyncInitCommonData')
        NProgress.done()
      })
      .catch(err => {
        console.log(err)
        store.dispatch('user/logout')
        next(`/login?redirect=${to.path}`)
      })
  } else {
    next()
  }
}

function change(arr) {
  var newArr = []
  arr.map(item =>
    item.children && item.children.length
      ? (newArr = newArr.concat(change(item.children)))
      : newArr.push(item)
  )

  return newArr
}
