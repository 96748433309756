<template>
  <required-form-item-select
    :disabled="!authorizedCityCode"
    class="formItem"
    :dataList="authorizedBrand"
    :value="value"
    labelKey="name"
    valueKey="brandId"
    :selectProps="{ disabled: false, multiple: true, clearable: true }"
    @changeSelectedItem="
      value => {
        getBrandData(value)
      }
    "
    v-bind="$attrs"
    v-on="$listeners" />
</template>

<script>
import { getShopBrandData, brandParentList } from '@/api/common/'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
export default {
  components: {
    RequiredFormItemSelect
  },
  props: {
    value: {
      type: Array,
      default: []
    },
    authorizedCityCode: {
      type: String,
      default: ''
    },
    businessLicenseId: {
      type: String,
      default: ''
    },
    filter: {
      type: Boolean,
      default: false
    },
    hiddenHas: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: 0
    },
    onlyClassic: {
      type: Boolean,
      default: false
    },
    hiddenBrandsIds: {
      type: Array,
      default: () => []
    },
    isOA: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      authorizedBrand: [], // 显示列表数据
      defaultBrand: [], // 默认显示数据
      allBrandList: [], // 所有品牌系列
      saveBrandList: [] //
    }
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  watch: {
    watchBusinessLicenseIdAndCityCode: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getBrandList()
        }
      }
    }
  },
  computed: {
    watchBusinessLicenseIdAndCityCode() {
      if (this.authorizedCityCode && this.businessLicenseId) {
        return `${this.authorizedCityCode}-${this.businessLicenseId}`
      }
      return ''
    }
  },
  created() {
    this.getAllBrand()
  },
  methods: {
    // 获取所有品牌列表
    async getAllBrand() {
      const res = await brandParentList({
        ifDelete: this.isOA ? -1 : 0
      })
      if (res.code === 0) {
        this.allBrandList = res.data.map(item => {
          return {
            ...item,
            bigBrandName: item.bigName
          }
        })
      }
    },
    // 获取已代理
    async getBrandList() {
      const res = await getShopBrandData({
        authorizedCityCode: this.authorizedCityCode,
        businessLicenseId: this.businessLicenseId,
        ifEnabled: 0,
        bigBrandCode: this.onlyClassic ? 'C' : ''
      })
      if (res.code === 0) {
        this.saveBrandList = res.data
        if (this.hiddenHas) {
          const dataList = res.data.map(item => item.brandId)
          // 已归档除外的数据筛选
          this.authorizedBrand = this.allBrandList.filter(
            item => !dataList.includes(item.brandId)
          )
          // 根据需要隐藏的品牌
          if (this.hiddenBrandsIds.length > 0) {
            this.authorizedBrand = this.authorizedBrand.filter(
              item => !this.hiddenBrandsIds.includes(item.brandId)
            )
          }
        } else {
          this.authorizedBrand = res.data
        }
        // 展示的列表数据赋值
        this.defaultBrand = this.authorizedBrand
        // 已归档不作限制
        if (this.status == 40) {
          this.authorizedBrand = this.allBrandList
          return
        }
      }
    },
    // 选择新系列代理品牌
    getBrandData(options) {
      // if (options.length == 0) return
      if (!options || options.length == 0) {
        this.saveSelect([])
        if (this.authorizedCityCode && this.businessLicenseId) {
          this.getBrandList()
        }
        return
      }
      if (!this.filter) {
        this.saveSelect(options)
        return
      }
      const [lastOption] = options.slice(-1)
      const bigBrands = lastOption.bigBrandName
      switch (bigBrands) {
        // case '慕思时尚':
        // case '慕思通配':
        //   this.saveSelect(this.ruleSelect(options))
        //   break;
        // case '慕思助眠':
        //   let data = null
        //   if (options.filter(i => {
        //       return i.bigBrandName != '慕思助眠'
        //     }).length != 0) {
        //     // this.$message.error('慕思助眠是独立店，不能和其他系列一起加盟')
        //     data = options.splice(options.length - 1, 1)
        //   }
        //   var dataList = this.authorizedBrand.filter(brand => {
        //     return brand.bigBrandName == '慕思助眠'
        //   })
        //   this.authorizedBrand = [...dataList]
        //   if (data) {
        //     this.saveSelect(data)
        //   }
        //   break;
        // case '慕思经典':
        // case '慕思儿童':
        // case '慕思家纺':
        //   var dataList = this.authorizedBrand.filter(brand => {
        //     return ['慕思经典', '慕思儿童', '慕思家纺'].includes(
        //       brand.bigBrandName
        //     )
        //   })
        //   this.authorizedBrand = [...dataList]
        //   break
        case '':
          this.authorizedBrand = [...this.defaultBrand]
          // this.saveSelect(options)
          break
        default:
          // console.log(bigBrands);
          var dataList = this.authorizedBrand.filter(brand => {
            return brand.bigBrandName == bigBrands
          })
          this.authorizedBrand = [...dataList]
          // this.saveSelect()
          break
      }
      // 赋值
      this.saveSelect(options)
    },

    // ruleSelect(data) {
    //   const arr = ['慕思通配', '慕思时尚']
    //   const newOptions = data.filter(item => {
    //     return arr.includes(item.bigBrandName)
    //   })
    //   const dataList = this.authorizedBrand.filter(brand => {
    //     return arr.includes(brand.bigBrandName)
    //   })
    //   this.authorizedBrand = [...dataList]
    //   return newOptions

    // },
    // commonSelect(data) {
    //   const arr = ['慕思通配', '慕思时尚', '慕思助眠']
    //   const newOptions = data.filter(item => {
    //     return !arr.includes(item.bigBrandName)
    //   })
    //   const dataList = this.authorizedBrand.filter(brand => {
    //     return !arr.includes(brand.bigBrandName)
    //   })
    //   this.authorizedBrand = [...dataList]
    //   return newOptions
    // },

    // 传值给父组件
    saveSelect(options) {
      this.$emit('onChangeSelectedItem', options)
      const data =
        options?.length > 0
          ? options.map(item => {
              return item.brandId
            })
          : []
      this.$emit('changeValue', data)
    }
  }
}
</script>

<style lang="scss" scoped></style>
