<template>
  <div class="worker">
    <div class="tag">
      <el-tag
        v-for="(item, index) in workerData"
        :key="index"
        closable
        :disable-transitions="true"
        @close="handleCloseTag(index)"
        >{{ item.name }}</el-tag
      >
    </div>
    <div class="searchBar">
      <el-input
        v-model.trim="searchVal"
        placeholder="请输入工号或姓名搜索"
        class="input-with-select"
        clearable
        @clear="hanldeClear"
        @keyup.enter.native="searchworkers">
        <el-button slot="append" icon="el-icon-search" @click="searchworkers" />
      </el-input>
    </div>
    <div>
      <div style="color: #cfbd95">
        <i class="el-icon-warning-outline"></i>
        多个工号用英文逗号分隔，一次性最多支持500个工号添加
      </div>
      <el-checkbox
        v-model="checkedAllTree"
        @change="handleCheckAll"
        v-show="treeSearchArr.length > 0"
        >全选</el-checkbox
      >
    </div>
    <el-tree
      ref="tree"
      style="height: 350px; overflow-y: auto"
      :props="defaultProps"
      :load="loadNode"
      lazy
      show-checkbox
      node-key="id"
      @check-change="handleCheckChange" />
    <span
      slot="footer"
      style="display: flex; justify-content: center; padding-top: 10px">
      <el-button
        type="primary"
        style="width: 200px"
        size="small"
        :disabled="disabled"
        @click="getworkers"
        >确认绑定</el-button
      >
    </span>
  </div>
</template>

<script>
import { getInput, getTree } from '@/api/common'
export default {
  data() {
    return {
      defaultProps: {},
      searchVal: '',
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: data => !data.isParent,
        disabled: 'disabled'
      },
      nodeHead: [], // 重复触发  动态更新tree
      resolveHead: [], // 重复触发  动态更新tree
      workerData: [],
      type: '',
      dataList: [],
      disabled: true,
      checkedAllTree: false,
      treeSearchArr: []
    }
  },
  watch: {
    workerData(val) {
      if (val) this.disabled = false
    }
  },
  methods: {
    handleCheckAll(val) {
      this.$refs.tree.setCheckedKeys(
        val ? this.treeSearchArr.map(item => item.id) : []
      )
    },
    hanldeClear() {
      this.searchVal = ''
      this.nodeHead.childNodes = []
      this.loadNode(this.nodeHead, this.resolveHead)
    },
    handleCloseTag(index) {
      this.workerData.splice(index, 1)
    },
    searchworkers() {
      this.nodeHead.childNodes = []
      this.loadNode(this.nodeHead, this.resolveHead)
    },
    // 树状图展开
    loadNode(node, resolve) {
      if (node.level === 0 && this.searchVal === '') {
        this.nodeHead = node
        this.resolveHead = resolve
        getTree().then(res => {
          res.forEach(item => {
            item.disabled = item.isParent
          })
          resolve(res)
        })
      }
      if (node.level === 0 && this.searchVal !== '') {
        const rule = /^[\u4e00-\u9fa5]+$/
        const type = rule.test(this.searchVal) ? 'username' : 'account'
        const formData = {
          page: 1,
          limit: 500,
          searchVal: this.searchVal,
          searchType: type
        }
        getInput(formData).then(res => {
          const lis = this.workerData.map(item => item.id)
          const nAr = res.page.list.map(item => {
            return {
              ...item,
              name: item.username + '(' + item.account + ')'
            }
          })
          // this.$refs.tree.setCheckedKeys(lis)
          this.treeSearchArr = nAr
          resolve(nAr)
        })
      }
      // if (node.level >= 1) {
      //   getTree({ id: node.data.id })
      //     .then(res => {
      //       res.forEach(item => {
      //         item.disabled = item.isParent
      //       })
      //       resolve(res)
      //       if (res.length && !res[0].isParent) {
      //         const lis = this.workerData.map(item => item.id)
      //         this.$refs.tree.setCheckedKeys(lis)
      //       }
      //     })
      //     .then(() => {
      //       let isNoParent = true
      //       isNoParent = node.childNodes.every(item => {
      //         return !item.data.isParent
      //       })
      //       if (node.childNodes.length > 0 && isNoParent) {
      //         node.data.disabled = false
      //       }
      //     })
      // }
    },
    // 选中工作人员
    getworkers() {
      this.$emit(
        'onBindUser',
        this.workerData.map(item => item.id)
      )
      this.workerData = []
      this.searchVal = ''
      this.nodeHead.childNodes = []
      this.loadNode(this.nodeHead, this.resolveHead)
    },
    // 节点处理
    handleCheckChange(data, checked, indeterminate) {
      if (!indeterminate && checked && !data.isParent) {
        this.workerData.push({
          $treeNodeId: data.$treeNodeId,
          ...data,
          userId: data.id
        })
      }
      if (!indeterminate && !checked) {
        this.workerData.forEach((item, index) => {
          if (item.id === data.id) {
            this.workerData.splice(index, 1)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.tag .el-tag) {
  margin: 5px 5px;
}
.tag {
  overflow: auto;
  max-height: 120px;
}
.searchBar {
  padding: 10px;
}
</style>
