// @ts-nocheck
import request from '@/utils/request'

//获取角色列表分页
export const roleListPage = params => {
  return request({
    url: '/user/role/listpage',
    method: 'get',
    params
  })
}

//获取所有角色列表
export const allRoleList = params => {
  return request({
    url: '/user/role/list',
    method: 'get',
    params
  })
}

//新增角色
export const roleAdd = data => {
  return request({
    url: '/user/role/add',
    method: 'post',
    data
  })
}

//修改角色
export const roleUpdate = data => {
  return request({
    url: '/user/role/update',
    method: 'post',
    data
  })
}

//角色添加菜单权限
export const roleBatchadd = data => {
  return request({
    url: '/user/rolemenu/batchadd',
    method: 'post',
    data
  })
}

//角色添加菜单权限
export const roleMenuList = params => {
  return request({
    url: '/user/rolemenu/list',
    method: 'get',
    params
  })
}

// 角色管理-获取角色授权用户
export const roleUserList = (params = {}) => {
  return request({
    url: `/user/accountrole/userListByRole`,
    method: 'get',
    params
  })
}

// 角色管理-绑定角色授权用户
export const roleBindUser = (data = {}) => {
  return request({
    url: `/user/accountrole/batchadd`,
    method: 'post',
    data
  })
}

// 角色管理-解除绑定角色授权用户
export const deleteRoleUser = (data = {}) => {
  return request({
    url: `/user/accountrole/delete`,
    method: 'post',
    data
  })
}
